import React from 'react';

import {
  Box,
  Spacer,
} from '@chakra-ui/react';

import { shortenAddress } from '../candy-machine';

export default function Header(props) {
  const { connected, address, onConnect, onDisconnect } = props;

  return (
    <>
      <Box className="header">
        <Box
          maxW="auto"
          mx="auto"
          marginRight={8}
          display="flex"
          justifyContent="space-between"
          alignItems="center"
        >
          <Spacer></Spacer>
          <Box display={{ base: 'block', md: 'flex' }} alignItems="center">

            {!connected ? (
              <Box
                as="button"
                minW="150px"
                h="40px"
                bg="grey"
                px="15px"
                borderRadius="5px"
                ml="15px"
                onClick={onConnect}
              >
                Connect Wallet
              </Box>
            ) : (
              <>
                <Box
                  as="button"
                  minW="150px"
                  h="40px"
                  bg="grey"
                  px="15px"
                  borderRadius="5px"
                  ml="15px"
                  onClick={onDisconnect}
                >
                  Disconnect Wallet
                </Box>
              </>
            )}
            <br />

          </Box>
        </Box>
        <Box
          maxW="auto"
          mx="auto"
          marginRight={8}
          display="flex"
          justifyContent="space-between"
          alignItems="center"
        >
          <Spacer></Spacer>
          {address && (
            <p className="address">
              <span className="font-bold">
                {shortenAddress(address || '')}
              </span>
            </p>
          )}
        </Box>
      </Box>
    </>
  );
}
