import React, { useCallback, useState, useEffect, useReducer } from 'react';
import { ChakraProvider, Box } from '@chakra-ui/react';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import WalletConnectProvider from '@walletconnect/web3-provider';
import Web3Modal from 'web3modal';
import { providers, Contract } from 'ethers';

import SmartContract from './contracts/TheIndifferentDev.json';
import { Home } from './page';
import Header from './components/Header';
import './App.css';

import beforeWhitelist from './music/beforeConnect.mp3'
import ahahah from './music/ahahah.mp3'

const INFURA_ID = '460f40a260564ac4a4f4b3fffb032dad';

const {
  REACT_APP_NETWORK_ID,
  REACT_APP_NETWORK_NAME,
  REACT_APP_CONTRACT_ADDRESS,
} = process.env;

const providerOptions = {
  walletconnect: {
    package: WalletConnectProvider, // required
    options: {
      infuraId: INFURA_ID, // required
    },
  },
};

let web3Modal = new Web3Modal({
  network: 'mainnet', // optional
  cacheProvider: true,
  providerOptions, // required
});

const initialState = {
  provider: null,
  web3Provider: null,
  address: null,
  chainId: null,
  smartContract: null,
};

function reducer(state, action) {
  switch (action.type) {
    case 'SET_WEB3_PROVIDER':
      return {
        ...state,
        provider: action.provider,
        web3Provider: action.web3Provider,
        address: action.address,
        chainId: action.chainId,
        smartContract: action.smartContract,
      };
    case 'SET_ADDRESS':
      return {
        ...state,
        address: action.address,
      };
    case 'SET_CHAIN_ID':
      return {
        ...state,
        chainId: action.chainId,
      };
    case 'RESET_WEB3_PROVIDER':
      return initialState;
    default:
      throw new Error();
  }
}

function App() {
  const [state, dispatch] = useReducer(reducer, initialState);
  // const [nftCount] = useState(1);
  const [setShowModal] = useState(true);
  const { provider, web3Provider, address, chainId, smartContract } = state;

  const [audio, setAudio] = useState(new Audio(beforeWhitelist));
  const [playing, setPlaying] = useState(false);

  const [audioAhahah, setAudioAhahah] = useState(new Audio(ahahah));
  const [playingAhahah, setPlayingAhahah] = useState(false);

  const connect = useCallback(async function () {
    // This is the initial `provider` that is returned when
    // using web3Modal to connect. Can be MetaMask or WalletConnect.
    const provider = await web3Modal.connect();

    // play audio
    setAudio(new Audio(beforeWhitelist))
    if (!playing) {
      audio.play()
      setPlaying(true)
    }

    // We plug the initial `provider` into ethers.js and get back
    // a Web3Provider. This will add on methods from ethers.js and
    // event listeners such as `.on()` will be different.
    const web3Provider = new providers.Web3Provider(provider);

    console.log(web3Provider)

    const signer = web3Provider.getSigner();
    const address = await signer.getAddress();

    const network = await web3Provider.getNetwork();
    const SmartContractObj = new Contract(
      REACT_APP_CONTRACT_ADDRESS,
      SmartContract.abi,
      signer
    );
    dispatch({
      type: 'SET_WEB3_PROVIDER',
      provider,
      web3Provider,
      address,
      chainId: network.chainId,
      smartContract: SmartContractObj,
    });

  }, []);

  const playAhahah = () => {
    // play audio
    setAudioAhahah(new Audio(ahahah))
    audioAhahah.play()
    // if (!playingAhahah) {
    //   audioAhahah.play()
    //   setPlayingAhahah(true)
    // }
  }

  const disconnect = useCallback(
    async function () {
      await web3Modal.clearCachedProvider();
      if (provider?.disconnect && typeof provider.disconnect === 'function') {
        await provider.disconnect();
      }
      dispatch({
        type: 'RESET_WEB3_PROVIDER',
      });
    },
    [provider]
  );

  const mintNFTs = async () => {

    console.log(smartContract)

    if (smartContract == null) {
      toast.error(
        "Connect wallet"
      );
      return
    }

    smartContract
      .mintDev(1,)
      .then(receipt => {
        toast.success(
          'Mint successful!'
        );
      }, (error) => {
        if (error.message == "MetaMask Tx Signature: User denied transaction signature.") {
          return
        }
        var newError = error.data.message.split("'");
        toast.error(
          newError[1].split("'")[0]
        );

      })
  };

  // Auto connect to the cached provider
  // Runs every rerender, 2th argument dependency, just run if connect changes
  useEffect(() => {
    if (web3Modal.cachedProvider) {
      connect();
    }
  }, [connect]);

  useEffect(() => {
    if (chainId && chainId !== Number(REACT_APP_NETWORK_ID)) {
      console.log(chainId)
      toast.error(`Change the network to ${REACT_APP_NETWORK_NAME}`, {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: false,
      });
    }
  }, [chainId]);

  // A `provider` should come with EIP-1193 events. We'll listen for those events
  // here so that when a user switches accounts or networks, we can update the
  // local React state with that new information.
  useEffect(() => {
    if (provider?.on) {
      const handleAccountsChanged = (accounts) => {
        // eslint-disable-next-line no-console
        console.log('accountsChanged', accounts);
        dispatch({
          type: 'SET_ADDRESS',
          address: accounts[0],
        });
      };

      // https://docs.ethers.io/v5/concepts/best-practices/#best-practices--network-changes
      const handleChainChanged = (_hexChainId) => {
        window.location.reload();
      };

      const handleDisconnect = (error) => {
        // eslint-disable-next-line no-console
        console.log('disconnect', error);
        disconnect();
      };

      provider.on('accountsChanged', handleAccountsChanged);
      provider.on('chainChanged', handleChainChanged);
      provider.on('disconnect', handleDisconnect);

      // Subscription Cleanup
      return () => {
        if (provider.removeListener) {
          provider.removeListener('accountsChanged', handleAccountsChanged);
          provider.removeListener('chainChanged', handleChainChanged);
          provider.removeListener('disconnect', handleDisconnect);
        }
      };
    }
  }, [provider, disconnect]);

  return (
    <ChakraProvider>
      <Box h="100vh" w="100vw">
        <Header
          connected={!!web3Provider}
          address={address}
          onConnect={connect}
          onDisconnect={disconnect}
          onShowModal={() => setShowModal(true)}
        />
        <Home mintNFTs={mintNFTs} onConnect={connect} address={address} onAhahah={playAhahah} />
      </Box>
      <ToastContainer />
    </ChakraProvider>
  );
}

export default App;
