import React, { useState, useEffect } from 'react';
import Terminal, { ColorMode, LineType } from 'react-terminal-ui';
import {
  Box
} from '@chakra-ui/react'
import axios from "axios";
import styles from "../styles/main.css";
import Typist from 'react-typist';
import 'react-typist/dist/Typist.css';
import { Modal } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.css";
import beforeWhitelist from '../music/beforeConnect.mp3'
import exampleImage from "../images/example.jpg"
import magicWord from "../images/magic-word-jurassic-park.gif"

const Home = (props) => {

  // test: "http://localhost:5000",
  // development: "http://localhost:5000",
  // production: "https://indifferent-devs-api.herokuapp.com",

  let api = "https://indifferent-devs-api.herokuapp.com"

  const { mintNFTs, onConnect, address, onAhahah } = props;

  const [whitePaperShown, setWhitepaperShow] = useState(false)

  const handleWhitePaperShown = () => setWhitepaperShow(true);
  const handleWhitepaperClose = () => setWhitepaperShow(false);

  const [magicWordShown, setMagicWordShow] = useState(false)

  const handleMagicWordShown = () => setMagicWordShow(true);
  const handleMagicWordClose = () => setMagicWordShow(false);

  const [terminalLineData, setTerminalLineData] = useState([
    { type: LineType.Output, value: <Typist avgTypingDelay='10' stdTypingDelay='0' cursor={{ show: false }}> </Typist > },
  ]);

  const [audio, setAudio] = useState(new Audio(beforeWhitelist));
  const [playing, setPlaying] = useState(false);

  function checkInput(terminalInput) {

    // get available commands
    if (terminalInput == "/help") {
      setTerminalLineData(state => [...state, { type: LineType.Output, value: <Typist avgTypingDelay='2' stdTypingDelay='0' cursor={{ show: false }}>/connect - connect your wallet, careful music!<br /> /whitepaper - show indiffernt devs whitepaper<br />/try + your guess - find a codeword & get whitelisted< br /> /free spots - are there whitelist spots left?< br /> /whitelisted - am I whitelisted?< br /> /clean - clean the console</Typist > }])
      return
    }

    // check for free spots
    if (terminalInput == "/free spots") {
      axios.get(api + "/getWhitelistData").then(response => {
        setText(response.data.openwhitelistSpots + "/228 whitelisting spots left")
      })
      return
    }

    if (terminalInput == "/whitepaper") {
      handleWhitePaperShown()
      return
    }

    if (terminalInput == "/clean") {
      setTerminalLineData(state => [])
      return
    }

    if (!terminalInput.startsWith("/")) {
      setText("unknown command")
      return
    }

    // connect wallet
    if (terminalInput == "/connect") {
      onConnect()
      return
    }

    // check if wallet was connected
    if (address == undefined) {
      setText("Connect wallet first!")
      return
    }

    if (terminalInput == "/mint") {
      mintNFTs()
    }

    // check if wallet is whitelisted
    else if (terminalInput == "/whitelisted") {
      axios.post(api + "/checkWhitelisted", { address: address }).then(response => {
        if (response.data == "you are not whitelisted") {
          setText("You are not whitelisted keep trying")
        }
        if (response.data == "you are whitelisted") {
          setText("You are whitelisted congrets fellow DEV!")
        }
      })
    }
    else if (terminalInput.startsWith("/try ")) {

      axios.post(api + "/whitelist", { address: address, keyword: terminalInput.slice(5).toLowerCase() }).then(response => {
        setText(response.data)
        if (response.data == "YOU DIDN'T SAY THE MAGIC WORD") {
          handleMagicWordShown()
          onAhahah()
        }
      })
    }
    else {
      setText("unknown command")
    }
  }

  function setText(outputText) {
    setTerminalLineData(state => [...state, { type: LineType.Output, value: <Typist avgTypingDelay='2' stdTypingDelay='0' cursor={{ show: false }}>{outputText}</Typist > }])
  }

  return (
    <Box
      w="100vw"
      h="100%"
      bg="gray.900"
      overflowX="hidden"
      id="sds"
      px={{ base: '15px', md: '20px' }}
      py={{ base: '20px', md: '30px' }}
    >
      <Box
        maxW="1200px"
        mx="auto"
        display="flex"
        marginTop="36px"
        flexDirection={{ base: 'column-reverse', lg: 'row' }}
        justifyContent="center"
        w="100%"
        h="75%"
      >

        <Modal show={magicWordShown} onHide={handleMagicWordClose} dialogClassName="magicWordModal" animation={false} centered>
          <Modal.Body id="magicWordModal">
            <img src={magicWord} />
          </Modal.Body>
        </Modal>

        <Modal show={whitePaperShown} onHide={handleWhitepaperClose} dialogClassName="whitepaperModal">
          <Modal.Body id="whitepaperContent">
            <h1 className="whitepaperContentTitle">devDAO.Intro</h1>
            <p>Greetings fellow dev, welcome to the devDAO and our first NFT project: `Indifferent Devs`.</p>

            <h1 className="whitepaperContentTitle2">Can devs do smth?</h1>

            <p>Indifferent Devs is an NFT project that serves as the starting point for the devDAO. First things first, we are not a fucking metaverse and no, there won`t be any landsale coming up.</p><br />
            <p>In short, our goal is to develop devDAO as a small, closed community of like minded techies that:</p><br />
            <div>
              <ol>
                <li>Share crypto alpha. Real alpha, not pamp and damp bs. We have a leaderboard for alpha sharing in place to track everyone's alpha sharing qualities. Exclusive subgroups are reserved for DAO members with the best track record and the ones that have proven themselves worthy. Read-only access will be managed through paid memberships and the membership fees will be fully distributed to DAO members (NFT holders).</li>
                <br />
                <li>As a team, we develop and aggregate tools through devDAO hackathons and integrate them into our discord. These tools will be exclusively accessible to devDAO members and will include NFT sniping bots & automated monitoring of successful NFT traders, automated token monitoring including onchain data and smart contract analysis of smol caps. Developers will be rewarded by the community.  Talk is cheap I hear them say? We have two initial tools up and running right now:</li>
                <ol type="a">
                  <li>devDAO.bot_whalewatcher: An on-chain monitoring discord bot that drops a message as soon as one of the addresses that DAO is monitoring buys, sells or transfers an NFT.</li>
                  <li>devDAO.bot_vibes: A discord sentiment bot. Fully automated sentiment analysis of discord channels that the DAO monitors. Using natural language processing (NLP) sentiment updates of monitored channels are published in the devDAO discord. We will be the first to know when sentiment shifts.</li>
                </ol>
                <br />
                <li>Acquire bluechip NFTs like (BAYC , NT, ...) that guarantee access to insider alpha.</li>
                <br />
                <li>Get our hands on whitelist spots and IDO allocation and distribute them to our members.</li>
              </ol>
            </div>

            <h1 className="whitepaperContentTitle2">Devs will do smth!</h1>

            <p>The mechanisms, as of now,  that we will use to develop the DevDAO are described in this whitepaper.  The spirit of devDAO will always be that we decide together via a fair voting system.</p> <br />

            <h1 className="whitepaperContentTitle2">devDAO.concepts</h1>
            <h1 className="whitepaperContentTitle3">Indifferent Dev - Genesis NFT collection</h1>
            There will only ever be 999 devDAO NFTs - this ensures that we stay a small and exclusive club with a real chance of getting to know each other and sharing valuable info, alpha and time together. If you are looking for a noisy discord with 20k members there are plenty of other projects out there for you.<br />
            The devDAO genesis collection will consist of 250 unique Indifferent Devs NFT characters.<br />
            <br />
            There will be different roles in the devDAO and holding a genesis devDAO NFT will grant you an exclusive role.<br />
            <img id="whitepaperExampleImage" src={exampleImage} />
            <ul>
              <li>Whitelisting: devDAO genesis minting will be whitelist-only. In order to get onto the whitelist you have to prove that you can beat the console. Hint for people who actually read the whitepaper: crypto memes are a good guess! We will be dropping further hints in our discord, so stay tuned.</li>
              <li>Minting price: 0.1000101 ETH</li>
            </ul>
            <br />

            <p>There will be 25 super rare 1/1 devDAO NFTs as part of the genesis collection that grant you access to the most exclusive subgroup.</p>
            <br />

            <h1 className="whitepaperContentTitle3">Upcoming NFT collections</h1>
            The remaining 724 NFTs will remain for upcoming collections that will be dropped in batches of no more than 181 at a time. Stay tuned in the devDAO discord, since all announcements will be dropped there first.<br />
            <br />

            <h1 className="whitepaperContentTitle3">devDAO.ASL - Alpha Sharing Leaderbord</h1>
            The only way to know whether a trader or investor is worth their salt, is their track record. We incentivize devDAO members to share good alpha by rewarding them with XP for a good shill and deducting XP from their balance for a bad shill. The XP score of all devDAO members will be publicly visible on the “Alpha Sharing Leaderbord” (devDAO.ASL). Higher XP scores will give members roles that provide access to more exclusive groups within the DAO. <br />
            More details about how this will be tracked via our discord bot will be published soon.<br />

            <br />
            <h1 className="whitepaperContentTitle3">devDAO.roles</h1>
            The roles in the devDAO are as follows<br />
            <br />
            <ul>
              <li>👶N00b
                <ul>
                  <li>holds no NFT</li>
                </ul>
              </li>
            </ul>
            <br />
            <ul>
              <li>🤓junior dev
                <ul>
                  <li>holds 1 devDAO NFT</li>
                </ul>
              </li>
            </ul>
            <br />
            <ul>
              <li>👨senior dev
                <ul>
                  <li>holds 3 devDAO NFTs</li>
                  <li>alternatively: top 20 by XP</li>
                </ul>
              </li>
            </ul>
            <br />
            <ul>
              <li>🧙🏿1337
                <ul>
                  <li>holds a 1/1 NFT</li>
                  <li>alternatively: top 5 by XP</li>
                </ul>
              </li>
            </ul>
            <br />

            <h1 className="whitepaperContentTitle3">devDAO.voting</h1>
            Everything in the devDAO can be changed, if the majority votes for it. NFTs act as governance tokens. We will implement a voting mechanism for this. The weight of one’s vote will depend on the member's role to ensure that established members have the biggest say while including the opinion of newly joined members as well. <br />
            <br />
            Voting will commence after the genesis NFT mint. Initial voting dates are:
            <br /><br />
            <ul>
              <li>01.03.2022 (open for 48h)
                <ul>
                  <li>How high will membership fees be for read-only access to the discord for the first 3 months?  (This will be voted on again every 3 months)</li>
                </ul>
              </li>
            </ul>
            <br />
            <ul>
              <li>01.05.2022 (open for 48h)
                <ul>
                  <li>Vote on mechanics for the devDAO.launchpad.</li>
                </ul>
              </li>
            </ul>
            <br />
            <ul>
              <li>01.07.2022 (open for 48h)
                <ul>
                  <li>Roadmap Q4 2022</li>
                </ul>
              </li>
            </ul>
            <br />
            <ul>
              <li>further dates to be published soon</li>
            </ul>
            <br />

            <h1 className="whitepaperContentTitle3">devDAO.subgroups</h1>
            According to your role in the devDAO you will have access to the respective subgroups. There might be more subgroups coming up in the future, depending on the decision of the DAO.<br />
            <br />

            <h1 className="whitepaperContentTitle3">devDAO.devpad</h1>
            Sharing alpha is one goal we want to achieve in the devDAO. Another one is to use the strength of our community to source further tools to monitor the markets, identify trends early and trigger data driven trades. The development cost of these tools will be covered by the DAO. The devpad will serve as a dev2earn platform to publish development jobs and negotiate with potential devs via a decentralized voting system. Prior to devDAO, this was called work2earn.<br />
            <br />

            <h1 className="whitepaperContentTitle3">devDAO.roadmap</h1>
            Q1 2022
            <ul>
              <li>Genesis.StealthLaunch.launch()</li>
              <li>Discord.MemeContest.launch() </li>
              <li>Discord.Games.launch()</li>
              <li>Discord.AlphaStreaming.launch()</li>
            </ul>

            Q2 2022
            <ul>
              <li>devDAO.init()
                <ul>
                  <li>devDAO.voting.init()</li>
                  <li>devDAO.get_alpha()</li>
                  <li>devDAO.share_alpha()</li>
                  <li>devDAO.treasury.init()</li>
                </ul>
              </li>
            </ul>

            Q3 2022
            <ul>
              <li>GitHub.devpad.init() </li>
            </ul>

            Q4 2022
            <ul>
              <li>To be voted on by DAO</li>
            </ul>
          </Modal.Body>

        </Modal>

        <Typist avgTypingDelay='0' stdTypingDelay='0' cursor={{ show: false }}>
          <div id="welcomeHeader">
            IndiffDevsShell 5000 v0.1
            <br />
            Copyright @2022 Indifferent Devs
            <br />
            ------------------------------------------------------------------------
            <br />
            <div className='ascii-art'>
              <span className='welcomeLLetter'> /$$    </span><br />
              <span className='welcomeLLetter'>| $$    </span><br />
              /$$  /$$  /$$  /$$$$$$ | $$  /$$$$$$$  /$$$$$$  /$$$$$$/$$$$   /$$$$$$<br />
              | $$ | $$ | $$ /$$__  $$| $$ /$$_____/ /$$__  $$| $$_  $$_  $$ /$$__  $$<br />
              | $$ | $$ | $$| $$$$$$$$| $$| $$      | $$  \ $$| $$ \ $$ \ $$| $$$$$$$$<br />
              | $$ | $$ | $$| $$_____/| $$| $$      | $$  | $$| $$ | $$ | $$| $$_____/<br />
              |  $$$$$/$$$$/|  $$$$$$$| $$|  $$$$$$$|  $$$$$$/| $$ | $$ | $$|  $$$$$$$<br />
              \_____/\___/  \_______/|__/ \_______/ \______/ |__/ |__/ |__/ \_______/<br />
            </div>
            <br />
            ------------------------------------------------------------------------
            <br />
            Whitelist spots are available for the most degen and creative devs!
            <br />
            ------------------------------------------------------------------------
            <br />
            Type /help for a list of available commands
          </div>
        </Typist >
      </Box >
      <Box
        maxW="1200px"
        mx="auto"
        display="flex"
        flexDirection={{ base: 'column-reverse', lg: 'row' }}
        justifyContent="center"
        alignItems="center"
        w="100%"
        h="25%"
      >
        <Terminal className={styles.terminal} colorMode={ColorMode.Dark} lineData={terminalLineData} onInput={terminalInput => checkInput(terminalInput)} />
      </Box>
    </Box >
  )
}

export default Home;
